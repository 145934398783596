import { performanceService } from "@/business/performanceService";
import HighchartComponent from '@/components/commons/highchart/HighchartComponent.vue';
import { mapState } from "vuex";
import { TimeRanges } from '@colven/common-domain-lib/lib';
import { processFilters } from "@/tools/functions";

export default {
  components: {
    HighchartComponent
  },
  props: {
    details: {
      type: Object,
      default: () => { return {} }
    },
    filters: {
      type: Object,
      default: () => { return {} }
    },
    showComparativeChart: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    charts: [],
    trackDetails: null,
    detailsKey: (Math.random() + 1).toString(36).substring(7),
    loadingChartTotal: false,
    loadingChartDetails: false,
    updateHighchart: false,
    backgroundColorTable: null,
    detailsTitle: '',
    comparativeButtons: [],
    comparativeChartSizeMin: 5,
    comparativeChartSizeMax: 80,
    comparativeChartSizeCurrent: 5,
    comparativeHide: true
  }),
  computed: {
    ...mapState('app', ['dark']),
    tableColors() {
      const isDark = this.dark;
      const themes = JSON.parse(localStorage.getItem('themes'));
      const colors = isDark ? themes.dark : themes.light;
      this.backgroundColorTable = this.convertHexToRGBA(colors.primary, 0.25);
      return { primary: colors.primary, secondary: 'white', accent: colors.accent }
    },
    reportByOurmeter: function () {
      return this.filters && this.filters.generalData.reportBy === "ODOMETER"
    }
  },
  mounted: async function () {
    await this.updateCharts(this.details);
    const colors = performanceService.getUserColors();
    this.tableColors.primary = colors.primary;
    this.tableColors.secondary = colors.secondary;
    this.tableColors.accent = colors.accent;
    this.backgroundColorTable = this.convertHexToRGBA(colors.primary, 0.25);
    this.detailsTitle = this.$t('performanceDetail.chartTracks.title');
    this.comparativeButtons.push({
      id: 'hideComponent', name: 'performanceDetail.chartComparative.showComparative', icon: 'fa-chart-bar', function: this.changeComparativeChartSize
    });
  },
  methods: {
    changeComparativeChartSize() {
      this.comparativeHide = !this.comparativeHide;
      if (!this.comparativeHide) {
        this.comparativeChartSizeCurrent = 40;
      } else {
        this.comparativeChartSizeMin = 5;
        this.comparativeChartSizeCurrent = 5;
      }
      this.onResizeChange();
    },
    updateCharts: async function (currentThing) {
      this.loadingChartTotal = true;
      this.loadingChartDetails = true;
      const colors = performanceService.getUserColors();
      this.tableColors.primary = colors.primary;
      this.tableColors.secondary = colors.secondary;
      this.tableColors.accent = colors.accent;
      this.backgroundColorTable = this.convertHexToRGBA(colors.primary, 0.25);
      const filterData = processFilters(this.filters);
      if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
        this.loadingChartDetails = false;
        this.trackDetails = await performanceService.generateChartDetailsByData(this.details.positions || [], currentThing.thingId, filterData.reportBy);
        this.detailsKey = (Math.random() + 1).toString(36).substring(7);
      } else {
        performanceService.getPerformanceChartTracksDetailsByFixedRange(currentThing.thingId, this.filters).then((data) => {
          this.loadingChartDetails = false;
          this.trackDetails = data;
          this.detailsKey = (Math.random() + 1).toString(36).substring(7);
        }).catch(() => {
          this.loadingChartDetails = false;
          this.trackDetails = null;
          this.detailsKey = (Math.random() + 1).toString(36).substring(7);
        });
      }
      performanceService.getPerformanceChartComparation(currentThing, this.filters).then((data) => {
        this.loadingChartTotal = false;
        this.charts.cleanAndUpdate(data);
      }).catch(() => {
        this.loadingChartTotal = false;
        this.charts.cleanAndUpdate([]);
      })
    },
    onResizeChange() {
      this.updateHighchart = !this.updateHighchart;
    },
    convertHexToRGBA(color, transparency) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)
      return `rgba(${r}, ${g}, ${b}, ${transparency})`
    }
  },
  watch: {
    details: function (newValue) {
      this.updateCharts(newValue);
    }
  }
}
