import * as Highcharts from 'highcharts/highstock';
import * as Exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { toggleFullscreen } from "@/tools/functions";
import store from "../../../store/store";
import i18n from "@/i18n";
Exporting(Highcharts);
NoDataToDisplay(Highcharts);

export default {
    name: 'HighchartComponent',
    components: {
    },
    props: {
        showFullscreen: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        dataChart: {
            type: Object,
            required: false,
            default: () => {
                return {
                    chart: {
                        renderTo: this.idChart,
                        reflow: true
                    },
                    exporting: {
                        enabled: true
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    lang: {
                        noData: i18n.t('noData')
                    }
                }
            }
        },
        //Property para que se pueda actualizar el gráfico en función al tamaño del splitpane que lo contiene
        updateHighchart: {
            type: Boolean,
            required: false,
            default: false
        },
        showPrint: {
            type: Boolean,
            required: false,
            default: false
        },
        exportName: {
            type: String,
            required: false,
            default: 'performanceDetails'
        },
    },
    data() {
        return {
            idChart: 'HighchartComponentId_' + (Math.random() + 1).toString(36).substring(7),
            chartInstance: null,
            chartComponentId: 'chartComponentId_' + (Math.random() + 1).toString(36).substring(7),
            fullscreenIcon: 'fullscreen',
            exportInstance: null
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.chartInstance.redraw();
            this.chartInstance.reflow();
        });
        window.addEventListener('keyup', e => {
            if (e.keyCode === 27) {
                this.switchFullscreenIcon()
            }
        });
        /* Standard syntax */
        document.addEventListener('fullscreenchange', () => {
            this.switchFullscreenIcon()
        })
        /* Firefox */
        document.addEventListener('mozfullscreenchange', () => {
            this.switchFullscreenIcon()
        })
        /* Chrome, Safari and Opera */
        document.addEventListener('webkitfullscreenchange', () => {
            this.switchFullscreenIcon()
        })
        /* IE / Edge */
        document.addEventListener('msfullscreenchange', () => {
            this.switchFullscreenIcon()
        });
        const timezone = store.getters["user/getEnterpriseTimezone"]; //new Date().getTimezoneOffset();
        Highcharts.setOptions({
            lang: {
                loading: 'Cargando...',
                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                exportButtonTitle: "Exportar",
                printButtonTitle: "Importar",
                rangeSelectorFrom: "Desde",
                rangeSelectorTo: "Hasta",
                rangeSelectorZoom: "Período",
                downloadPNG: 'Descargar imagen PNG',
                downloadJPEG: 'Descargar imagen JPEG',
                downloadPDF: 'Descargar imagen PDF',
                downloadSVG: 'Descargar imagen SVG',
                printChart: 'Imprimir',
                resetZoom: 'Reiniciar zoom',
                resetZoomTitle: 'Reiniciar zoom',
                thousandsSep: ",",
                decimalPoint: '.'
            },
            global: {
                timezoneOffset: timezone
            }
        });
        this.chartInstance = Highcharts.stockChart(this.idChart, this.chartData || {
            chart: {
                renderTo: this.idChart,
                reflow: true
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            lang: {
                noData: i18n.t('noData')
            }
        });
        this.exportInstance = Highcharts.stockChart(this.idChart+'_highChartExportID', this.chartData || {
            chart: {
                renderTo: this.idChart,
                reflow: true
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            lang: {
                noData: i18n.t('noData')
            }
        });
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        updateHighchart() {
            this.chartInstance ? this.chartInstance.reflow() : null;
        }
    },
    computed: {
        chartData() {
            if (this.dataChart) {
                return {
                    ...this.dataChart,
                    chart: {
                        ...this.dataChart.chart,
                        renderTo: this.idChart,
                        reflow: true
                    },
                    lang: {
                        noData: i18n.t('noData')
                    }
                }
            }
            return null;
        }
    },
    methods: {
        exportPDF() {
            this.exportInstance.exportChart(
                {
                    type: 'application/pdf',
                    filename: `${this.exportName}`
                },
                {
                    navigator: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    xAxis: {
                        max: null,
                        scrollbar: {
                            enabled: false
                        }
                    }
                }
            );
        },
        exportPNG() {
            this.exportInstance.exportChart(
                {
                    type: 'image/png',
                    filename: `${this.exportName}`
                },
                {
                    navigator: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    xAxis: {
                        max: null,
                        scrollbar: {
                            enabled: false
                        }
                    }
                }
            );
        },
        exportJPG() {
            this.exportInstance.exportChart(
                {
                    type: 'image/jpeg',
                    filename: `${this.exportName}`
                },
                {
                    navigator: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    xAxis: {
                        max: null,
                        scrollbar: {
                            enabled: false
                        }
                    }
                }
            );
        },
        fullscreen() {
            const highchartComponentElement = document.getElementById(this.chartComponentId);
            this.fullscreenIcon = toggleFullscreen(highchartComponentElement);
        },
        switchFullscreenIcon() {
            if (!document.fullscreenElement && !document.mozFullScreenElement &&
                !document.webkitFullscreenElement && !document.msFullscreenElement) {
                this.fullscreenIcon = 'fullscreen'
            } else {
                this.fullscreenIcon = 'fullscreen_exit'
            }
        },
        resetZoom() {
            this.chartInstance.zoomOut();
        },
        print() {
            this.chartInstance.print();
        }
    },
}
