import reportStorageApi from '@/api/report-storage.api';
import { breadcrumbService } from "@/business/breadcrumb.service";
import { configurationService } from "@/business/configurationService";
import { filterService } from "@/business/filterService";
import { performanceService } from "@/business/performanceService";
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue';
import FilterComponent from "@/components/filter/FilterComponent.vue";
import PerformanceDetailComponent from '@/components/performance-detail/PerformanceDetailComponent.vue';
import { REPORT_TYPES, SnackbarStyle } from '@/constants/constants';
import i18n from "@/i18n";
import middleware from "@/middleware";
import store from "@/store/store";
import { mapActions, mapMutations } from 'vuex';
import { TimeRanges } from '@colven/common-domain-lib/lib';
import { unitParser } from '@colven/common-domain-lib/lib';

export default {
    components: {
        BreadcrumbComponent,
        PerformanceDetailComponent,
        FilterComponent
    },
    data: () => ({
        model: {
            charts: [],
            maps: [],
            table: {
                headers: [],
                data: [],
            },
        },
        middleware: middleware,
        loading: false,
        title: i18n.t('performance'),
        tableDataKey: 'number',
        tableFilterRules: {},
        disableRefreshTable: true,
        loadingTable: false,
        getDetailsForTable: null,
        getDetailsForChart: null,
        reportDetails: "Deatils",
        tableConfig: {},
        // botones de cada fila
        rowActions: [{
            id: 1,
            name: i18n.t('detail'),
            color: 'blue',
            icon: 'mdi-file-document-outline',
        }],
        highlightRow: null,
        // tabs para el breadcrumb
        tabs: [{
            id: 'tab-summary',
            name: i18n.t('performanceSummary.breadcrumbTabSummary')
        }],
        // tab seleccionada
        currentTab: 'tab-summary',
        // botones del breadcrumb
        breadcrumbButtons: [],
        detailModel: {
            name: "test"
        },
        filters: filterService.getFilterModel(),
        initPosition: null,
        showFilters: false,
        maxQuantityLimit: 10,
        orderFilterWorstBestPropertyName: unitParser.changeUnit(i18n.t(
            "dischargeSummary.filterProperties.worstBestProperty"
        ), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) }),
        dataForRangeFilter: {
            showRangeData: false,
            minLimit: 0,
            maxLimit: 1000,
            propertyName: unitParser.changeUnit(i18n.t("performanceSummary.filterProperties.rangeProperty"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) }),
        },
        timeRangeIsNotCustom: true
    }),
    created() {
        this.breadcrumbButtons.push(
            {
                id: 'setting-btn',
                icon: 'settings',
                show: () => { return true },
                disable: () => { return false },
                function: this.selector.bind(this)
            }
        );
    },
    async mounted() {
        this.loading = true;
        this.resetBreadcrumbSelectors();
        const tableConfiguration = await configurationService.get("performanceSummary.table");
        this.tableConfig = (tableConfiguration && tableConfiguration.data) || {};
        try {
            const reportKey = this.$route.query.key
            const report = reportKey && await reportStorageApi.getReport(reportKey);
            let performance;
            if (report && report.filters) {
                this.filters = report.filters;
            } else {
                if (Object.keys(this.$route.params).length !== 0) {
                    this.filters = this.$route.params;
                    performance = await performanceService.getPerformanceSummary(this.filters);
                } else {
                    const configuration = await configurationService.get(REPORT_TYPES.PERFORMANCE_SUMMARY);
                    this.filters = configuration && configuration.data && configuration.data.filters
                        ? configuration.data.filters
                        : filterService.getFilterModel();
                }
            }
            if (report && !performance) {
                performance = await performanceService.generateReportByData(report.data, this.filters);
            }
            this.setReport(performance);
            if (this.filters.dateData && this.filters.dateData.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
                this.timeRangeIsNotCustom = false;
            } else {
                this.timeRangeIsNotCustom = true;
            }
        } finally {
            this.loading = false;
        }

    },
    beforeDestroy() {
        this.closeReportDefer();
    },
    methods: {
        ...mapActions({
            showSnackbar: "snackbar/showSnackbar"
        }),
        ...mapMutations('reportDefer', {
            showReportDefer: 'showReportDefer',
            closeReportDefer: 'closeReportDefer',
            commitVisible: 'commitVisible'
        }),
        async setReportByKey(reportKey) {
            const report = reportKey && await reportStorageApi.getReport(reportKey);
            let performance;
            if (report && report.filters) {
                this.filters = report.filters;
            } else {
                if (Object.keys(this.$route.params).length !== 0) {
                    this.filters = this.$route.params;
                    performance = await performanceService.getPerformanceSummary(this.filters);
                } else {
                    const configuration = await configurationService.get(REPORT_TYPES.PERFORMANCE_SUMMARY);
                    this.filters = configuration && configuration.data && configuration.data.filters
                        ? configuration.data.filters
                        : filterService.getFilterModel();
                }
            }
            if (report && !performance) {
                performance = await performanceService.generateReportByData(report.data, this.filters);
            }
            this.setReport(performance);
            if (this.filters.dateData && this.filters.dateData.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
                this.timeRangeIsNotCustom = false;
            } else {
                this.timeRangeIsNotCustom = true;
            }
        },
        setReport(performance) {
            if (performance) {
                this.initPosition = performance.initPosition;
                this.model.charts.cleanAndUpdate(performance.chart);
                this.model.maps.cleanAndUpdate(performance.maps);
                this.model.table.headers.cleanAndUpdate(performance.table.headers);
                this.model.table.data.cleanAndUpdate(performance.table.data);
                store.dispatch("breadcrumb/setEntitySelectorItems", []);
                breadcrumbService.setSummaryDetailsByFilters(this.filters, 'PERFORMANCE');
                this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "PERFORMANCE");
                this.getDetailsForTable = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
                this.getDetailsForChart = breadcrumbService.getFilterDetailsHTMLExport(this.filters, "PERFORMANCE");
            } else {
                this.cleanData();
                this.showFilters = true;
            }
        },
        cleanData() {
            this.initPosition = null;
            this.model.charts.cleanAndUpdate([]);
            this.model.maps.cleanAndUpdate([]);
            this.model.table.headers.cleanAndUpdate([]);
            this.model.table.data.cleanAndUpdate([]);
            store.dispatch("breadcrumb/setEntitySelectorItems", []);
            breadcrumbService.setSummaryDetailsByFilters(this.filters, 'PERFORMANCE');
            this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "PERFORMANCE");
            this.getDetailsForTable = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
            this.getDetailsForChart = breadcrumbService.getFilterDetailsHTMLExport(this.filters, "PERFORMANCE");
        },
        closeFilter() {
            this.showFilters = false;
        },
        disableSelectorButton() {
            this.showFilters = false;
        },
        selector() {
            this.showFilters = !this.showFilters;
        },
        async saveFilterChanges() {
            configurationService
                .save(REPORT_TYPES.PERFORMANCE_SUMMARY, { filters: this.filters })
                .then(() => {
                    // this.showSnackbar({
                    //     visible: true,
                    //     text: i18n.t("user.configuration.saveSuccess"),
                    //     timeout: 10000,
                    //     style: SnackbarStyle.SUCCESS
                    // });
                })
                .catch(() => {
                    // this.showSnackbar({
                    //     visible: true,
                    //     text: i18n.t("user.configuration.saveError"),
                    //     timeout: 10000,
                    //     style: SnackbarStyle.ERROR
                    // });
                });
            this.closeFilter();
            this.cleanData();
            if (this.currentTab !== "tab-summary") {
                this.tabSelected("tab-summary");
            }
            this.loading = true;
            try {
                const result = await performanceService.getPerformanceSummary(this.filters);
                if (result) {
                    this.setReport(result);
                } else {
                    if (this.loading) this.commitVisible({ visible: true })
                    this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
                }
            } finally {
                this.loading = false;
            }
            if (this.filters.dateData && this.filters.dateData.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
                this.timeRangeIsNotCustom = false;
            } else {
                this.timeRangeIsNotCustom = true;
            }

        },
        resetBreadcrumbSelectors() {
            store.dispatch("breadcrumb/setEntitySelectorItems", []);
        },
        /**
         * Método que recibe los datos de la fila clickeada, obtiene el nro de fila que la identifica y llama a filtrar las descargas en el mapa.
         * @param {*} rowData
         */
        focusOnThingInMapByRowClick(rowData) {
            this.centerMap(rowData.position);
            this.highlightSelectedRow(rowData.number);
        },
        /**
         * Centra el mapa en la posición recibida.
         * TODO: Actualizar método si se implementa en el componente MapComponent una prop reactiva o modelo y un watch
         * para ejecutar el centrado y así no usar referencias al DOM físico
         * @param {*} param0 
         */
        centerMap(position) {
            const mapRef = this.$refs.performanceSummaryReportMap;
            if (mapRef != null && position != null && position.lat != null && position.lng != null) {
                mapRef.centerMap(position)
            }
        },
        refreshTable() {
        },
        saveConfig(data) {
            configurationService.save("performanceSummary.table", data)
                .then(() => {
                    this.showSnackbar({
                        visible: true,
                        text: i18n.t("user.configuration.saveSuccess"),
                        timeout: 10000,
                        style: SnackbarStyle.SUCCESS
                    });
                })
                .catch(() => {
                    this.showSnackbar({
                        visible: true,
                        text: i18n.t("user.configuration.saveError"),
                        timeout: 10000,
                        style: SnackbarStyle.ERROR
                    });
                });
        },
        // eslint-disable-next-line no-unused-vars
        rowButtonClicked(button, data) {
            switch (button.id) {
                case 1:
                    this.goToDetail(data)
                    break;
                default:
                    console.log('default');
            }
        },
        /**
         * Ir al detalle
         * @param {*} selectedItem
         */
        // eslint-disable-next-line no-unused-vars
        goToDetail(selectedItem) {
            if (this.tabs.length === 1) {
                this.tabs.push({
                    id: 'tab-detail',
                    name: i18n.t('detail')
                })
            }
            performanceService.loadBreadcrumbSelector(this.model.table.data, {
                thingId: selectedItem.thingId,
                thingName: selectedItem.thingName
            });
            this.changeDetail(selectedItem);
            this.currentTab = 'tab-detail'
        },
        /**
         * Cambia los datos del detalle cuando se selecciona otro item del selector del breadcrumb
         * @param {*} selectedItem
         */
        // eslint-disable-next-line no-unused-vars
        changeDetail(selectedItem) {
            const dataSelected = this.model.table.data.find(element => element.thingId === selectedItem.thingId)
            this.detailModel = performanceService.getDetailsData(dataSelected, this.model.table.data);
            this.detailModel.reportDetails = breadcrumbService.getFilterDetailsHTMLExport(this.filters, null, this.detailModel.thingName);
        },
        /**
         * Método para resaltar una fila en la tabla
         * @param {*} number
         */
        highlightSelectedRow(number) {
            if (number != null) {
                this.highlightRow = null;
                const that = this;
                this.$nextTick(() => {
                    that.highlightRow = number;
                });
            }
        },
        /**
         * cuando se hace click en un marcador del mapa, se resalta la fila en la tabla
         * @param {*} number
         */
        onMapClick({ number }) {
            this.highlightSelectedRow(number);
        },
        filtersChanged(data) {
            configurationService.save("performanceSummary.map", { currentLayer: data.currentLayer });
        },
        /**
         * Acción ejecutada al cambiar de tab (breadcrumb)
         * @param {*} id
         */
        tabSelected(id) {
            this.currentTab = id
            if (this.currentTab === 'tab-summary' && this.tabs.length === 2) {
                this.tabs.pop();
                this.resetBreadcrumbSelectors();
            }
        }
    },
};